import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import NavBar from "./NavBar";
import { StaticImage } from "gatsby-plugin-image";

const Header = () => {
  const location = useLocation();

  let backgroundImage;
  let heading;
  let description;

  if (location.pathname === "/") {
    backgroundImage = "/images/melbourne-city.webp";
    heading = "Groundwater now and for the future";
    description = "";
  } else if (location.pathname === "/about-the-logo/") {
    backgroundImage = "/images/color-book.webp";
    heading = "About the Logo";
    description = "Discover the meaning and inspiration behind the logo of the 2025 World Groundwater Congress.";
} else if (location.pathname === "/host-and-committee/") {
    backgroundImage = "/images/waterdrop.webp";
    heading = "Host and Committee";
    description = "Meet the dedicated hosts and committee members orchestrating the 2025 World Groundwater Congress.";
  } else if (location.pathname === "/accommodation/") {
    backgroundImage = "/images/color-book.webp";
    heading = "Accommodation";
    description = "Find the best places to stay during your event.";
  } else if (location.pathname === "/visit-victoria/") {
    backgroundImage = "/images/mcec.webp";
    heading = "Visit Victoria";
    description = "Explore various destinations in Victoria.";
  } else if (location.pathname === "/congress-venue/") {
    backgroundImage = "/images/mcec.webp";
    heading = "Congress Venue";
    description = "Get all the details about the congress venue, including location and facilities.";
  } else if (location.pathname === "/melbourne-bucket-list/") {
    backgroundImage = "/images/mcec.webp";
    heading = "Melbourne Bucket List";
    description = "Discover must-see attractions and activities to enjoy while in Melbourne.";
  } else if (location.pathname === "/sponsorship/") {
    backgroundImage = "/images/waterdrop.webp";
    heading = "Sponsorship";
    description = "Explore our sponsorship opportunities and join us in making this event a success.";
  } else if (location.pathname === "/sponsors-and-exhibitors/") {
    backgroundImage = "/images/waterdrop.webp";
    heading = "Sponsors & Exhibitors";
    description = "Discover our top-tier sponsors and exhibitors.";
  } else if (location.pathname === "/latest-news/") {
    backgroundImage = "/images/waterdrop.webp";
    heading = "Latest News";
    description = "Stay updated with the latest news and updates.";
  } else if (location.pathname === "/promotional-toolkit/") {
    backgroundImage = "/images/waterdrop.webp";
    heading = "Promotional Toolkit";
    description = "Download assets for your promotional materials.";
  } else if (location.pathname === "/contact/") {
    backgroundImage = "/images/waterdrop.webp";
    heading = "Contact Us";
    description = "For all general enquiries, please contact the Congress Secretariat.";
  } else if (location.pathname === "/privacy-policy/") {
    backgroundImage = "/images/waterdrop.webp";
    heading = "Privacy Policy";
    description = "Learn about our privacy policy and how we handle your personal data.";
  }

  return (
    <>
      <header
        className="relative text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-gray-900 opacity-60"></div>
        <section className="relative z-10" id="inline-navbar">
          <div className="relative container mx-auto flex items-center justify-between py-10 px-6">
            <div className="flex items-center">
              <Link className="flex items-center" to="/">
                <StaticImage 
                  src="../images/IAH2025-web-2xlogos.png" 
                  alt="IAH 2025 logo" 
                  width={600} 
                  layouts="constrained"
                  placeholder="blurred"
                  formats={["auto", "webp", "avif"]}
                />
              </Link>
            </div>
            <NavBar />
          </div>
          
        </section>

        <section className="relative z-10 py-20">
          <div className="container mx-auto grid grid-cols-1 gap-12 px-6">
            <div className="space-y-6">
              <h1 className="text-4xl font-bold leading-tight text-white sm:text-5xl md:text-6xl">
                {heading}
              </h1>
              <p className="text-white text-lg">
                {description}
              </p>
            </div>
            <div className="relative rounded-lg overflow-hidden">

            </div>
          </div>
        </section>

      </header>

    </>
  );
}

export default Header;
